import type {ShouldUpdateScrollArgs} from 'gatsby'
import './src/styles/global.css'

export const shouldUpdateScroll = ({prevRouterProps, routerProps, getSavedScrollPosition}: ShouldUpdateScrollArgs): boolean | string | number[] => {
    // 過去のスクロール位置が保持されている場合はデフォルト挙動とする
    const currentPos = getSavedScrollPosition(routerProps.location)
    const currentY = currentPos ? currentPos[1] : 0
    if (currentY > 0) {
        return true
    }

    if (prevRouterProps) {
        // 上記以外で内部のページ遷移であれば、 #content-main の位置までスクロールする
        const contentMainContainer = document.getElementById('content-main')
        const scrollToTop = contentMainContainer?.offsetTop
        if (scrollToTop && scrollToTop > 0) {
            contentMainContainer?.scrollIntoView()
            return false
        }
    }

    // 上記の条件に合致しない場合はデフォルト挙動とする
    return true
}
